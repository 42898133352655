<template>
  <div class="container-app">
    <div class="arrow-container -expand-arrows-animation">
      <div class="arrow"></div>
      <div class="arrow"></div>
      <div class="arrow"></div>
      <div class="arrow"></div>
      <div class="arrow"></div>
    </div>

    <div class="container-quiz" v-if="!submitted">
      <div class="header-quiz">
        <h1>Basic Info</h1>
      </div>
      <div class="form-group mt-5">
        <label>Test Name</label>
        <input
          type="input"
          v-model="title"
          class="form-control mb-4"
          name="title"
        />
        <label for="startTime">Test Start Time (date and time):</label>
        <input
          type="datetime-local"
          v-model="start_time"
          class="form-control mb-4"
          name="startTime"
        />
        <label for="duration">Duration:</label>
        <input
          type="number"
          v-model="duration"
          class="form-control mb-2"
          name="duration"
          placeholder="Minutes"
        />
        <button class="btn btn-success" @click="validate1">Next</button>
      </div>
    </div>
    <div class="container-quiz" v-else>
      <div class="header-quiz">
        <h1>Quiz Created</h1>
      </div>
      <button class="btn btn-success my-5" @click="redirect">Edit Test</button>
    </div>
  </div>
</template>
<script>
import db from "../../firebase.js";
import axios from "axios";
import store from "@/store";
export default {
  props: ["test_id", "page_id"],
  mounted() {
    var config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post(
        "https://Pysberduck.pythonanywhere.com/api/edit-test",
        {
          test_id: this.test_id,
          page_id: this.page_id,
        },
        config
      )
      .then((res) => {
        if (res.data["status"] == "200") this.questions = res.data["data"];
        db.collection("tests" + this.page_id)
          .doc(this.test_id)
          .get()
          .then((doc) => {
            this.title = doc.data().title;
            this.duration = doc.data().duration;
            this.sections = doc.data().sections;
            this.maxsection = doc.data().sections.length;
            this.current_section = this.maxsection;
          });
      });
    store.state.loading = false;
  },
  data() {
    return {
      submitted: false,
      title: "",
      start_time: "",
      duration: "",
      testid: "",
      current_section: 0,
      sections: [],
      maxsection: 0,
      nameP: 0,
      questions: [],
    };
  },
  computed: {
    getStartDate() {
      if (this.start_time !== "") {
        return new Date(this.start_time);
      }
    },
  },
  methods: {
    redirect() {
      this.$router.push({
        name: "EditTest",
        params: { test_id: this.test_id, page_id: this.page_id },
      });
    },
    validate1() {
      let curr = new Date();
      if (
        this.duration >= 1 &&
        this.title != "" &&
        this.start_time != "" &&
        this.getStartDate >= curr &&
        this.sections != null
      ) {
        db.collection("tests" + this.page_id)
          .doc(this.test_id)
          .update({
            title: this.title,
            start: this.getStartDate,
            duration: +this.duration,
            sections: this.sections,
          })
          .then((doc) => {
            this.redirect();
          });
      } else
        swal("Duration>=1 minutes and Title!='' and StartTime>CurrentTime");
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  letter-spacing: 2px;
}

.container-app {
  min-height: 100%;
  width: 100%;
  position: absolute;
  width: 70%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.25em;
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  padding: 10vmin;
}
.editable-ques {
  width: 60%;
  margin-top: 20px;
}
.header-quiz {
  display: flex;
  width: 100%;
  height: 20%;
  border-bottom: 1px solid #e7eae0;
  justify-content: center;
  align-items: center;
  background-color: #ffc4c4;
  border-radius: 10px 10px 0px 0px;
}

.container-app {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.container-quiz {
  display: flex;
  min-width: 40%;
  height: 85%;
  background-color: white;
  text-align: center;
  flex-flow: column;
  border: 1px solid #ffc4c4;
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.questions {
  width: 100%;
}
button {
  border-radius: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: 2%;
}
input-file {
  display: none;
}
.btn-primary {
  background-color: #ff7a7a;
  border-top-color: #f88888;
  border-left-color: #d86d6d;
  border-right-color: #d86d6d;
  border-bottom-color: #bb5f5f;
  border-width: 4px;
}
.btn-success {
  background-color: #ff3c3c;
  border-top-color: #f88888;
  border-left-color: #d86d6d;
  border-right-color: #d86d6d;
  border-bottom-color: #bb5f5f;
  border-width: 4px;
}

.arrow {
  position: absolute;
  left: -15vmax;
  bottom: -25vmax;
  display: block;
  width: 30vmax;
  height: 30vmax;
  --arrow-scale: 1;
  background: #f74e4e;
  box-shadow: 0 0 7vmax rgba(50, 50, 50, 0.2);
  z-index: -1;
  cursor: pointer;
}
.input-file {
  display: none;
}
.-expand-arrows-animation .arrow {
  animation: expand-arrows 1s forwards;
}

.arrow:nth-child(2) {
  --arrow-scale: 2;
  background: #ff6868;
  z-index: -2;
}

.arrow:nth-child(3) {
  --arrow-scale: 3;
  background: #f77777;
  z-index: -3;
}

.arrow:nth-child(4) {
  --arrow-scale: 4;
  background: #f88e8e;
  z-index: -4;
}

.arrow:nth-child(5) {
  --arrow-scale: 5;
  background: #f09f9f;
  z-index: -5;
}

* {
  overflow-y: hidden;
}
@keyframes expand-arrows {
  0% {
    transform: scale(0) skew(-15deg, -15deg) rotate(-20deg);
  }

  100% {
    transform: scale(var(--arrow-scale)) skew(-15deg, -15deg) rotate(-20deg);
  }
}
</style>
